<template>
    <div class="bind-email">
        <!-- 綁定郵箱找回 -->
        <van-nav-bar
            title="綁定郵箱"
            left-text="返回"
            left-arrow
            @click-left="goBack"
        />

        <div class="content">
            <div class="email-tips">
                <p>您正在為賬號 {{ username }} 綁定郵箱，綁定郵箱能有效保障帳號安全，可通過郵箱找回密碼。</p>
            </div>

            <van-form v-if="type == 'old'" @submit="type = 'new'">
                <van-field
                    v-model="oldEmail"
                    label="綁定郵箱"
                    placeholder="請輸入舊郵箱"
                    clearable
                    center
                    left-icon="envelop-o"
                    :rules="[{ validator:validatorEmail, required: true, message: '請正確輸入郵箱' }]"
                />
                <van-field
                    v-model="oldVerify"
                    label="驗證碼"
                    center
                    left-icon="lock"
                    placeholder="請輸入舊郵箱驗證碼"
                    :rules="[{ required: true, message: '請輸入舊郵箱驗證碼' }]">
                    <template #button>
                        <van-button size="small" type="primary" color="#6e87ea" @click="sendOldVerify" :disabled="oldVerifyDisabled"> {{ oldVerifyBtnText }} </van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">下一步</van-button>
                </div>
            </van-form>

            <van-form v-else @submit="handleBind">
                <van-field
                    v-model="email"
                    label="綁定郵箱"
                    placeholder="請輸入您要綁定的郵箱"
                    clearable
                    center
                    left-icon="envelop-o"
                    :rules="[{ validator:validatorEmail, required: true, message: '請正確輸入郵箱' }]"
                />
                <van-field
                    v-model="verify"
                    label="驗證碼"
                    center
                    left-icon="lock"
                    placeholder="請輸入郵箱驗證碼"
                    :rules="[{ required: true, message: '請輸入郵箱驗證碼' }]">
                    <template #button>
                        <van-button size="small" type="primary" color="#6e87ea" @click="sendVerify" :disabled="verifyDisabled">{{ verifyBtnText }}</van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">立即綁定</van-button>
                </div>
            </van-form>

            <div class="email-tips">
                <p>*溫馨提示</p>
                <p>部分免費email郵箱，信件有可能被誤判為垃圾信，請先至【垃圾信箱】查看獲取驗證碼。</p>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter} from 'vue-router'
import { Toast } from 'vant'
import { validateEmail } from '@/utils/validate';
import { requestEmailVerifycode, requestBindEmail } from '@/service/user'

export default {
    setup() {
        const router = useRouter()
        const store = useStore()

        const state = reactive({
            type: 'old',
            username: '',
            email: '',
            verify: '',
            verifyTime:0,
            verifyDisabled: false,
            verifyBtnText: '發送驗證碼',

            oldEmail: '',
            oldVerify: '',
            oldVerifyTime:0,
            oldVerifyDisabled:false,
            oldVerifyBtnText: '發送驗證碼'
        })
        
        onMounted(() => {
            init()
        });
        
        const init = async () => {
            state.username = store.getters.username 

            state.oldEmail = store.getters.email || ''
            if (state.oldEmail == "") {
                state.type = "new";
            }
        }

        const sendOldVerify = async () => {
            if (state.oldEmail == '' || !validatorEmail(state.oldEmail)) {
                Toast("請正確輸入郵箱帳號")
                return;
            }
            Toast.loading('發送中');
            await requestEmailVerifycode({email:state.oldEmail});
            state.oldVerifyTime = 30
            onOldVerifyTimer()
            Toast("發送成功")
        }

        const onOldVerifyTimer = () => {
            if (state.oldVerifyTime > 0) {
                state.oldVerifyDisabled = true;
                state.oldVerifyTime --;
                state.oldVerifyBtnText = `重新發送(${state.oldVerifyTime})`;
                setTimeout(onOldVerifyTimer, 1000);
            } else{
                state.oldVerifyTime = 0;
                state.oldVerifyBtnText = "發送驗證碼";
                state.oldVerifyDisabled = false;
            }
        }

        const sendVerify = async () => {
            if (state.email == '' || !validatorEmail(state.email)) {
                Toast("請正確輸入郵箱帳號")
                return;
            }
            Toast.loading('發送中');
            await requestEmailVerifycode({email:state.email});
            state.verifyTime = 30
            onVerifyTimer()
            Toast("發送成功")
        }

        const onVerifyTimer = () => {
            if (state.verifyTime > 0) {
                state.verifyDisabled = true;
                state.verifyTime --;
                state.verifyBtnText = `重新發送(${state.verifyTime})`;
                setTimeout(onVerifyTimer, 1000);
            } else{
                state.verifyTime = 0;
                state.verifyBtnText = "發送驗證碼";
                state.verifyDisabled = false;
            }
        }

        const handleBind = async () => {
            Toast.loading('發送中');
            await requestBindEmail({
                oldVerifyCode:state.oldVerify,
                email:state.email,
                verify:state.verify
            });
            Toast("綁定成功")
            goBack()
        }

        const validatorEmail = (email) => {
            return validateEmail(email)
        }

        const goBack = () => {
            router.go(-1)
        }

        return {
            ...toRefs(state),
            sendOldVerify,
            sendVerify,
            validatorEmail,
            handleBind,
            goBack
        }
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.bind-email {
    height: calc(var(--vh, 1vh) * 100);
    .content {
        margin-top: 10px;
    }
}
.email-tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}
</style>